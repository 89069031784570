footer {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  background-color: var(--footer-bg-color);
  color: var(--footer-text-color);
}
footer .links {
  display: flex;
  justify-content: center;
  padding: calc(var(--grid) * 2) calc(var(--grid) * 4);
}
footer .links a {
  color: var(--footer-link-color);
  text-decoration: none;
  padding: 0 var(--grid);
}
footer .copyright {
  text-transform: uppercase;
  border-top: var(--border);
  padding: calc(var(--grid) * 2) calc(var(--grid) * 4);
}
footer .copyright p {
  margin: 0;
  text-align: center;
}
