header {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
}

header .topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--main-bg-color);
  padding: 0 calc(var(--grid) * 4);
  height: 42px;
  box-sizing: border-box;
  color: var(--main-btn-text);
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
}

header img {
  height: 75px;
  padding: calc(2 * var(--grid)) 0;
}
@media only screen and (max-width: 600px) {
  header img {
    height: 50px;
    padding: calc(0.625 * var(--grid)) 0;
  }
}
header .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
header .logo a {
  display: flex;
}
header .social {
  display: flex;
}
header .icon {
  display: flex;
  flex: 0 0 auto;
  padding: calc(var(--grid) * 0.75);
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  color: var(--main-btn-text);
  height: 30px;
  width: 30px;
}
header .icon:hover {
  color: rgba(255,255,255,.6);
}
header .icon svg {
  height: 42px;
  width: 42px;
}
header .menubar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
header .menu-toggle {
  appearance: none;
  background: transparent;
  border: none;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 30px;
  line-height: 30px;

  position: absolute;
  left: calc(3 * var(--grid));
  color: var(--menu-toggle-color);
}
@media only screen and (max-width: 600px) {
  header .menu-toggle {
    font-size: 22px;
  }
}
header .menu-overlay {
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.7);
}
header nav {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background-color: var(--secondary-bg-color);
}

header nav div {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  font-weight: 800;
}
header nav a {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  padding: 0 calc(2.5 * var(--grid));
  height: 50px;
  border-bottom: var(--border);
  color: var(--menu-link-text);
  text-transform: uppercase;
  text-decoration: none;
}
header nav .sub-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
}
header nav .sub-menu a {
  color: var(--body-text);
  text-transform: none;
  font-size: 14px;
  font-weight: 600;
}
