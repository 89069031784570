#root {
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
  height: 100vh;
}
.app {
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  overflow: hidden;
}
h1 {
  display: flex;
  justify-content: center;
  margin: 0;
  line-height: 46px;
  font-size: 36px;
  font-weight: 400;
}
.link, .button, .inline-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 800;

}
.inline-link {
  color: var(--main-link-text);
  text-transform: uppercase;
  letter-spacing: .3px;
  border: none;
  background: transparent;
  font-size: inherit;
  line-height: inherit;
  justify-content: inherit;
}
.link {
  flex: 1 1 100%;
  width: 100%;
  padding: var(--grid) 0;
  color: var(--main-link-text);
  text-transform: uppercase;
  letter-spacing: .3px;
  border: none;
  background: transparent;
}
.button {
  flex: 1 0 auto;
  text-align: center;
  background-color: var(--main-btn-color);
  color: var(--main-btn-text);
  padding: calc(1.25 * var(--grid)) calc(1.75 * var(--grid));
  border: none;
  font-size: 12px;
}
.select-list {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  z-index: 5;
  box-shadow: var(--box-shadow);
  margin: var(--grid);
  color: var(--body-text);
  background-color: var(--secondary-bg-color);
}
.select-list span {
  padding: 0 var(--grid);
  cursor: pointer;
}
.select-list span:hover {
  background-color: var(--alt-bg-color);
}
.select-toggle {
  display: flex;
  align-items: center;
  color: var(--main-link-text);
  padding: 0 var(--grid);
}
.select-toggle svg {
  display: flex;
  padding-left: calc(0.5 * var(--grid));
  position: relative;
  top: 2px;
}
.select-toggle .inline-link {
  padding: 0;
}
