.loading {
  display: flex;
  flex: 1 0 100%;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}
.dealers {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 0 1 auto;
  overflow: hidden;
}
.dealers .header {
  display: flex;
  flex: 1 1 50%;
}
.dealers .content {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .dealers .content {
    flex-direction: column;
  }
}
.online-dealers {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.online-dealers .content {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.online-dealers p {
  text-align: center;
}
.list {
  display: flex;
  flex: 0 1 50%;
  flex-direction: column;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  min-width: 325px;
  max-width: 500px;
  box-sizing: border-box;
}
@media only screen and (max-width: 600px) {
  .list {
    flex-grow: 1;
  }
}
.grid {
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  justify-content: center;
}
.grid .dealer {
  display: flex;
  flex: 0 1 auto;
  box-sizing: border-box;
}
.grid .dealer a {
  display: flex;
}
.grid .dealer img {
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .grid .dealer {
    flex-basis: 45%;
  }
}
.dealer {
  position: relative;
  padding: calc(var(--grid) * 1.5) calc(var(--grid) * 3);
  box-shadow: 0 0 4px rgba(0,0,0,.12);
  transition: box-shadow .35s ease;
  margin: var(--grid);
  cursor: pointer;
}
.dealer.selected {
  background-color: var(--list-bg-selected);
}
.dealer img,
.dealer h2,
.dealer p {
  margin: 0 0 var(--grid);
  display: flex;
  align-items: center;
}
.dealer p a {
  align-items: center;
  color: var(--main-link-text);
  text-decoration: none;
  font-weight: 800;
  vertical-align: middle;
}
.dealer a svg {
  padding-left: calc(0.5 * var(--grid));
}
.dealer .icon {
  display: flex;
  margin-right: var(--grid);
  width: 14px;
  justify-content: center;
}
.dealer .caret {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  right: var(--grid);
  font-size: 24px;
  color: var(--main-link-text);
}
.dealers .search-results .content {
  display: flex;
  flex: 1 0 auto;
  margin: 0;
  padding: var(--grid);
  box-sizing: content-box;
  height: 39px;
  justify-content: center;
  align-items: center;
}
.dealers .search {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.dealers .input-group {
  display: flex;
  padding: var(--grid);
}
.dealers .search input {
  appearance: none;
  box-sizing: border-box;
  border: none;
  box-shadow: 0 0 4px rgba(0,0,0,.12);
  display: flex;
  flex: 1 0 auto;
  padding: var(--grid) calc(var(--grid) * 1.5);
}
.dealers .search-results {
  display: flex;
  flex-direction: column;
}
#map {
  display: flex;
  flex: 1 1 50%;
  order: 1;
}
@media only screen and (max-width: 600px) {
  #map {
    order: 0;
    flex: 0 0 33%;
    min-height: 70vw;
  }
}
