.posts {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}
.post {
  display: flex;
  flex-direction: column;
  flex: 1 0 33%;
  min-width: 375px;
  padding: var(--grid);
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 4px rgba(0,0,0,.12);
  transition: box-shadow .35s ease;
  margin: var(--grid);
}
.post .created {
  position: absolute;
  top: calc(var(--grid) * 2);
  left: calc(var(--grid) * 2);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  min-width: 53px;
  text-align: center;
}
.post .created .day {
  padding: calc(var(--grid) / 1.333) 0 calc(var(--grid) / 2);
  font-size: 24px;
}
.post .created .month {
  padding: calc(var(--grid) / 4) 0 var(--grid);
  text-transform: uppercase;
  font-size: 12px;
}
.post img {
  max-width: 100%;
}
.post .content {
  padding: calc(var(--grid) * 3);
  box-sizing: border-box;
}
.post img,
.post h2,
.post p {
  margin: 0 0 var(--grid);
}
.post h2 {
  text-align: center;
}
